export type Filter = {
  id: number;
  type: string;
  title: {
    ru: string,
    cn: string,
    ar: string
  };
};

export type FilterCheckbox = Filter & {
  checked: boolean;
};

export type FilterObj = Record<
  string,
  [
    Filter
  ]
>;

export type FilterObjCheckbox = Record<
  string,
  [
    FilterCheckbox
  ]
>;

export enum PaintingsStatus {
  'for sale' = 'Для продажи',
  'not for sale' = 'Не для продажи',
}

export type DeliveryInfo = {
  index: null | number;
  region: { value: 'string' | null, title?: string };
  city: { value: 'string' | null, title?: string };
  address: string;
  date: null | Date;
  timeFrom: null | Date;
  timeTo: null | Date;
  comment: string;
};
export type UserFormTypes =
  | 'personal'
  | 'educ & qualif'
  | 'public & exhibit'
  | 'concept';

export const keys = [
  'price_from',
  'price_to',
  'height_from',
  'height_to',
  'width_from',
  'width_to',
  'year_from',
  'year_to',
  'in_sale',
  'location'
] as const

export type CatalogKeys = (typeof keys)[number];
