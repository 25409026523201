import { render, staticRenderFns } from "./index.vue?vue&type=template&id=577ec6fd&scoped=true"
import script from "./index.vue?vue&type=script&lang=ts"
export * from "./index.vue?vue&type=script&lang=ts"
import style0 from "./index.vue?vue&type=style&index=0&id=577ec6fd&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "577ec6fd",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LayoutHeader: require('/var/www/arthall/arthall.front/components/layout/LayoutHeader.vue').default,LayoutFooter: require('/var/www/arthall/arthall.front/components/layout/LayoutFooter.vue').default})
